import styled from '@emotion/styled'
import { keyframes } from '@emotion/css'
import { colorsPN, metricsPN } from '@news-mono/design-tokens'
import { zIndex } from '../../../../../__styling/settings/z-index'
import { breakpoint, calcRem } from '../../../../../__styling'
import { playButtonStates } from '../../../../../buttons/PlayButton/PlayButton.styled'
import { StyledKicker } from '../../../cards/PNArticleCard/PNArticleCardHeader/PNArticleCardHeader.styled'

const { icon, shadow } = colorsPN
const { spacing, radius, layout } = metricsPN

export const StyledPNPlayOverlay = styled('div')(
    {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        cursor: 'pointer',
    },

    ({ theme }) => ({
        [`${StyledKicker} &`]: {
            display: 'none',
        },
        ...playButtonStates(theme),
    }),
)

const spinnerShow = keyframes({
    to: {
        visibility: 'visible',
    },
})

const spinnerSpin = keyframes({
    '100%': {
        transform: 'rotate(360deg)',
    },
})

const spinnerFade = keyframes({
    '0%': {
        borderTopColor: icon.inverse,
    },
    '20%': {
        borderTopColor: icon.inverse,
    },
    '35%': {
        borderTopColor: shadow.blurs35,
    },
    '60%': {
        borderTopColor: icon.inverse,
    },
    '100%': {
        borderTopColor: icon.inverse,
    },
})

// Animated spinner that inherits the styling from .vjs-big-play-button
export const StyledPNSpinner = styled('div')({
    border: `6px solid ${shadow.blurs35}`,
    boxSizing: 'border-box',
    backgroundClip: 'padding-box',
    position: 'absolute',
    zIndex: zIndex.common.videoPlayButton,
    width: calcRem(spacing['2XL']),
    height: calcRem(spacing['2XL']),
    borderRadius: calcRem(radius.XL),
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    animation: `0s linear 0.3s forwards ${spinnerShow}`,
    WebkitAnimation: `0s linear 0.3s forwards ${spinnerShow}`,

    '&:before, &:after': {
        content: `''`,
        position: 'absolute',
        margin: calcRem(-spacing['3XS']),
        boxSizing: 'inherit',
        width: 'inherit',
        height: 'inherit',
        borderRadius: 'inherit',
        opacity: 1,
        border: 'inherit',
        borderColor: 'transparent',
        borderTopColor: icon.inverse,
        animation: `${spinnerSpin} 1.1s cubic-bezier(0.6, 0.2, 0, 0.8) infinite,
        ${spinnerFade} 1.1s linear infinite`,
        WebkitAnimation: `${spinnerSpin} 1.1s cubic-bezier(0.6, 0.2, 0, 0.8) infinite,
        ${spinnerFade} 1.1s linear infinite`,
    },

    '&:before': {
        borderTopColor: icon.inverse,
    },
    '&:after': {
        borderTopColor: icon.inverse,
        animationDelay: '0.44s',
    },

    [breakpoint('sm')]: {
        width: calcRem(layout.section.tablet),
        height: calcRem(layout.section.tablet),
        borderRadius: calcRem(radius.XL),
    },
})
