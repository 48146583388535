import { useTheme } from '@emotion/react'
import {
    ConsentLevel,
    PlayerEvent,
    Product,
    useConsentGiven,
    useFeature,
} from '@news-mono/web-common'
import React, { useEffect, useState } from 'react'
import { fixedPlaylistHeight } from '../../content/Video/components/VideoPlaylist/VideoPlaylist.styled'
import { useGdprMode } from '../../content/Video/utils/gdpr-mode'
import { Video } from '../../content/Video/Video'
import { videoWrapperClassName } from '../../content/Video/VideoSkin'
import { ArticleVideoProps } from '../../publication/ArticleVideo/ArticleVideo'
import {
    articleVideoClassName,
    stickyVideoClassName,
    StyledArticleVideo,
    StyledArticleVideoContainer,
    StyledFigcaption,
} from '../../publication/ArticleVideo/ArticleVideo.styled'
import { videoContainerClassName } from '../../templates/Publication/SevenNewsPublication/SevenNewsPublication'
import { ConsentFallback } from '../../tracking/ConsentFallback/ConsentFallback'
import {
    responsiveComponent,
    ResponsiveProps,
} from '../../__helpers/responsive-component'
import { DimensionProps, withDimensions } from '../../__helpers/with-dimensions'
import { breakpoints } from '../../__styling/settings/breakpoints'

export type PropsWithResponsive = ArticleVideoProps &
    ResponsiveProps &
    DimensionProps

const UnwrappedArticleVideo: React.FC<PropsWithResponsive> = (props) => {
    const theme = useTheme()

    const [isCurrentlySticky, setIsCurrentlySticky] = useState(false)
    // P19-703 We only want sticky videos if autoplay is on or the player has been interacted with (played)
    const [userInteracted, setUserInteracted] = useState(
        props.autoplayOptions ? true : false,
    )

    const [hideSticky, setHideSticky] = useState(false)

    const gdprMode = useGdprMode()
    const consentGiven = useConsentGiven({
        consentRequiredLevel: ConsentLevel.Advertising | ConsentLevel.Analytics,
    })
    const canLoadVideoPlayer = gdprMode || consentGiven

    const handleUserInteraction = (e: PlayerEvent) => {
        if (e.type === 'video.play') {
            setUserInteracted(true)
        }
        props.onEvent(e)
    }

    useEffect(() => {
        const detectSticky = (entries: IntersectionObserverEntry[]) => {
            // Setup the sticky behaviour - sticky should be applied to the container
            // not the video directly because position:sticky sticks items to
            // their parent container not the viewport.

            const stickyElement = document.querySelector(
                '.sticky-cell',
            ) as HTMLElement

            // cast to boolean because Sticky.isActive defaults to true
            const stickyActive = !!(
                props.viewportWidth >= breakpoints.md &&
                // P19-703 We only want sticky videos if autoplay is on or the player has been interacted with (played)
                userInteracted
            )

            if (
                stickyActive &&
                stickyElement &&
                entries[0].isIntersecting === false
            ) {
                // If intersectionRatio is 0, the target is out of view
                stickyElement.classList.add(stickyVideoClassName)
                setIsCurrentlySticky(true)
            } else {
                stickyElement &&
                    stickyElement.classList.remove(stickyVideoClassName)
                setIsCurrentlySticky(false)
            }
        }

        let stickyObserver: IntersectionObserver | undefined
        const options = {
            rootMargin: '630px 0px 0px 0px',
            threshold: 1.0,
        }

        if (props.isVideoStickyEnabled && 'IntersectionObserver' in window) {
            const stickyElement = document.querySelector(
                '.sticky-cell',
            ) as HTMLElement

            // Set height of container so that it doesn't jank around when the width/height changes
            stickyElement &&
                stickyElement.style.setProperty(
                    '--stickyHeight',
                    `${stickyElement.getBoundingClientRect().height + 20}px`, // Add extra 20px padding to prevent sticky overlapping mrec
                )

            stickyObserver = new IntersectionObserver(detectSticky, options)

            const stickyWatcher = document.querySelector('.sticky-top')
            stickyWatcher && stickyObserver.observe(stickyWatcher)

            // Sevennews is slightly different in that it's position fixed instead of sticky
            // this changes how the page flows
            if (theme.kind === Product.SevenNews) {
                // People may scroll before playlist has loaded, if this is the case the heights are all off and the page janks around
                // so have hardcoded it if it's going to be there (it's height is fixed regardless of viewport)
                const playlistHeight = props.showPlaylist
                    ? fixedPlaylistHeight
                    : 0
                const stickyContainer = document.querySelector(
                    `.${videoContainerClassName}`,
                ) as HTMLElement
                const videoHeight = document.querySelector(
                    `.${videoWrapperClassName}`,
                ) as HTMLElement
                const figCaptionHeight = document.querySelector(
                    '.sticky-container .figcaption',
                ) as HTMLElement

                stickyContainer &&
                    stickyContainer.style.setProperty(
                        '--stickyContainerHeight',
                        `${
                            videoHeight.getBoundingClientRect().height +
                            figCaptionHeight.getBoundingClientRect().height +
                            playlistHeight +
                            22.5
                        }px`, // Add in extra 40px to account for margins
                    )
            }
        }
        return () => {
            if (stickyObserver) {
                stickyObserver.disconnect()
            }
        }
    }, [
        props.isVideoStickyEnabled,
        props.viewportWidth,
        props.showPlaylist,
        theme.kind,
        userInteracted,
    ])

    const isMagniteEnabled = useFeature('magnite-header-tag-wrapper')

    const {
        byline,
        captionText,
        credit,
        kind = 'video',
        videoDetails,
        isHeroVideo,
        autoplayOptions,
        autoplayNextOptions,
        figcaptionRenderOptions,
        playButtonText,
        isVideoHub,
        publicUrl,
        publicationPathname,
        onPlayNextVideo,
        setVideoRendered,
    } = props

    if (!videoDetails.id) {
        return null
    }

    if (!canLoadVideoPlayer) {
        return <ConsentFallback />
    }

    const shouldShowPlaylist =
        props.showPlaylist && (!isCurrentlySticky || theme.kind === 'sevennews')

    const isCompactMode = isCurrentlySticky || props.videoOptions.compactMode

    const videoElement = (
        <Video
            ref={setVideoRendered}
            videoDetails={videoDetails}
            showTitles={props.showTitles}
            pauseOverlay={props.pauseOverlay}
            autoplayOptions={autoplayOptions}
            autoplayNextOptions={autoplayNextOptions}
            videoOptions={{
                ...props.videoOptions,
                compactMode: isCompactMode,
                isMagniteEnabled,
            }}
            innerRef={props.innerRef}
            getVideoQueue={props.getVideoQueue}
            onEvent={props.onEvent}
            pagePosition={props.isHeroVideo ? 'hero-video' : 'inline-article'}
            onUserInteraction={handleUserInteraction}
            showPlaylist={shouldShowPlaylist}
            playButtonText={
                isVideoHub
                    ? 'Play' // required to enable mobile play button in video hub
                    : playButtonText
                    ? playButtonText
                    : undefined
            }
            hideSticky={hideSticky}
            setHideSticky={setHideSticky}
            isVideoHub={isVideoHub}
            publicUrl={publicUrl}
            currentPath={publicationPathname}
            onPlayNextVideo={onPlayNextVideo}
            adElement={props.adElement}
        />
    )

    return (
        <StyledArticleVideo
            isHeroVideo={isHeroVideo}
            className={articleVideoClassName}
        >
            <StyledArticleVideoContainer isHeroVideo={isHeroVideo}>
                {videoElement}
            </StyledArticleVideoContainer>

            {figcaptionRenderOptions === 'hidden' ? ( //check if hidden and add empty StyledFigcaption because needed for sticky video to be there
                <StyledFigcaption isHeroVideo={isHeroVideo} />
            ) : isCompactMode ? (
                !hideSticky &&
                (captionText || credit || byline) && (
                    <StyledFigcaption
                        byline={byline}
                        captionText={captionText}
                        credit={isHeroVideo ? undefined : credit}
                        isHeroVideo={isHeroVideo}
                        kind={kind}
                        figcaptionLayout={figcaptionRenderOptions}
                        sticky
                    />
                )
            ) : (
                (captionText || credit || byline) && (
                    <StyledFigcaption
                        byline={byline}
                        captionText={captionText}
                        credit={isHeroVideo ? undefined : credit}
                        isHeroVideo={isHeroVideo}
                        kind={kind}
                        figcaptionLayout={figcaptionRenderOptions}
                    />
                )
            )}
        </StyledArticleVideo>
    )
}

export const WebArticleVideo = responsiveComponent<ArticleVideoProps>(
    withDimensions<ArticleVideoProps & ResponsiveProps>(UnwrappedArticleVideo),
)
export default WebArticleVideo
