import { useTheme } from '@emotion/react'
import {
    ConfigurationContext,
    IsHydrating,
    PagePosition,
    PlayerEvent,
    useFeature,
    VideoDetails,
    VideoQueueItemMeta,
} from '@news-mono/web-common'
import React, { useContext, useState } from 'react'
import * as H from 'history'
import { useLocation } from 'react-router'
import { Logger } from 'typescript-log'
import { useScript } from 'use-script'
import { Button } from '../../buttons/Button/Button'
import {
    AutoplayOptions,
    GetVideoQueue,
    PlayerInterface,
    PlayerProvider,
    VideoOptions,
} from '../../content/Video/PlayerInterface'
import { VideoSkin } from '../../content/Video/VideoSkin'
import { LoggerContext } from '../../diagnostics/LoggerContext'
import { ErrorBoundary } from '../../errors/ErrorBoundary/ErrorBoundary'
import { inViewport, WithAddedProps } from '../../__helpers/in-viewport'
import { useNielsenApid } from '../../__helpers/nielsen-apid'
import { VideoHubVideoSkinV2 } from '../../VideoHub/VideoSkinV2/VideoHubVideoSkinV2'
import { LazySwmPlayerProvider } from './providers/LazySwmPlayerProvider'
import { StyledVideoErrorBoundary } from './Video.styled'
import { VideoHubVideoSkin } from '../../VideoHub/VideoSkinV2/VideoHubVideoSkin'
import { PNVideoSkin } from '../../nextgen-news/perthnow/publication/PNVideoSkin/PNVideoSkin'
import {
    PNPlayerInterface,
    PNRenderPlayerParams,
} from '../../nextgen-news/perthnow/publication/PNVideoSkin/PNPlayerInterface'
import { RenderPlayerParams } from './player-render-params'

/**
 * Use the player provider context to change the player provider used in the video component. This
 * is useful for passing in a test provider. This provider can be monitored to ensure that the player
 * component is working correctly
 */
export const playerProviderContext = React.createContext<
    (logger: Logger) => PlayerProvider
>((logger) => {
    // bc _should_ always be defined at this point in production,
    // but this will save the day if brightcove ever starts serving dodgy scripts
    // it also reduces noise on product tests in verbose mode
    const brightcoveGlobalExists = typeof bc !== 'undefined'

    if (!brightcoveGlobalExists) {
        logger.warn('Global brightcove object does not exist')
    }

    return brightcoveGlobalExists ? LazySwmPlayerProvider : () => null
})

// We don't actually care about the type of this
declare const bc: undefined | any

export interface VideoProps extends WithAddedProps {
    videoDetails: VideoDetails

    videoOptions: VideoOptions

    // todo do something with this
    pauseOverlay?: boolean

    // shows the video title bar
    showTitles: boolean

    /** false to disable autoplay */
    autoplayOptions: AutoplayOptions | false

    autoplayNextOptions: AutoplayOptions | false

    onEvent: (event: PlayerEvent) => void

    /**
     * defaults to 'standard'
     */

    getVideoQueue: GetVideoQueue

    pagePosition: PagePosition

    /**
     * The `playWhenReady` prop tells the video to try and play the video. You can use this prop to try autoplay the
     * video immediately, but its preferable to use the autoplay options for that instead. This prop should only be used
     * if you need to effect the playing of the video component from its the parent component.
     */
    playWhenReady?: boolean

    onUserInteraction: (e: PlayerEvent) => void

    showPlaylist?: boolean
    playButtonText?: string

    /**
     * hideSticky state hook is drilled down from ArticleVideo.web.tsx - they were originally in
     * VideoSkin.tsx with the button that uses setHideSticky()
     *
     * This was done so the figcaption + video could be hidden when the video is closed
     */
    hideSticky?: boolean
    setHideSticky?: React.Dispatch<React.SetStateAction<boolean>>
    isVideoHub?: boolean
    publicUrl?: string
    currentPath?: string
    onPlayNextVideo?: (nextVideo: VideoQueueItemMeta) => void
    adElement?: JSX.Element
    canAutoPlay?: boolean
}

export const Video = inViewport<VideoProps>((props) => {
    const theme = useTheme()
    const getCustomPlayerProvider = useContext(playerProviderContext)
    const logger = useContext(LoggerContext)
    const clientConfig = useContext(ConfigurationContext)
    const nielsenApid = useNielsenApid()

    const { error, loading } = useScript({
        src: require('!!file-loader?esModule=false&outputPath=static/js!./player_4761797480001_g0iOzsBYC.min.js'),
    })

    const hydrating = useContext(IsHydrating)
    // Use !!props.autoplayOptions instead of true to only load automatically when autoplay is on
    const [loadPlayer, setLoadPlayer] = useState(true)
    const location = useLocation<{ pathname: string }>()

    const isVideoHubPlayListFixEnabled = useFeature(
        '7-news-video-hub-playlist-fix',
        false,
    )
    const isNgnEnabled = useFeature('perthnow-ngn-redesign-master', false)
    const isSevenV2VideoHubEnabled =
        theme.kind === 'sevennews' && useFeature('7-news-2-video-hub', false)

    const LoadingSkin = isNgnEnabled ? PNVideoSkin : VideoSkin
    const Interface = isNgnEnabled ? PNPlayerInterface : PlayerInterface

    // TODO Handle script loading error
    if ((hydrating || loading || error || !loadPlayer) && !props.isVideoHub) {
        return (
            <LoadingSkin
                theme={theme}
                playerActions={{
                    playVideo: () => {
                        setLoadPlayer(true)
                    },
                    handleMuteToggle: () => {},
                    stopAutoplay: () => {},
                    closeStickyVideo: () => {},
                    pauseVideo: () => {},
                    resumeVideo: () => {},
                    handleUserInactive: () => {},
                }}
                playerOptions={{
                    compactMode: props.videoOptions.compactMode,
                    playButtonText: props.playButtonText,
                }}
                playerState={{
                    adStarted: false,
                    adPlayed: false,
                    adPlaying: false,
                    adPaused: false,
                    videoStarted: false,
                    videoPlaying: false,
                    videoPaused: false,
                    canAutoplay: false,
                    currentState: { type: 'other' },
                    currentVideo: props.videoDetails,
                    currentVideoMetadata: undefined,
                    currentVideoPlaylistType: 'not-playlist-video',
                    inViewport: props.inViewport,
                    isFullscreen: false,
                    isMuted: false,
                    showInternalPlayButton: true,
                    showTitleOverlay: false,
                    showTopBar: false,
                }}
                videoElement={null}
                wrapperElProps={{
                    onMouseEnter: () => {},
                    onMouseLeave: () => {},
                    onMouseMove: () => {},
                    onTouchEnd: () => {},
                    onTouchStart: () => {},
                    innerRef: () => {},
                }}
                hideSticky={props.hideSticky}
                setHideSticky={props.setHideSticky}
            />
        )
    }

    return (
        <ErrorBoundary
            log={logger}
            renderError={(retry) => (
                <StyledVideoErrorBoundary>
                    <p>An error has occurred with this video.</p>
                    <Button onClick={() => retry()}>Click here to retry</Button>
                </StyledVideoErrorBoundary>
            )}
        >
            <Interface
                {...props}
                theme={theme}
                config={{ ...clientConfig, nielsenApid }}
                logger={logger}
                playerProvider={() => getCustomPlayerProvider(logger)}
                renderPlayer={(renderPlayerProps) =>
                    props.isVideoHub ? (
                        isSevenV2VideoHubEnabled ? (
                            <VideoHubVideoSkinV2
                                {...(renderPlayerProps as RenderPlayerParams)}
                                hideSticky={props.hideSticky}
                                setHideSticky={props.setHideSticky}
                                publicUrl={props.publicUrl}
                                currentPath={props.currentPath}
                                onPlayNextVideo={props.onPlayNextVideo}
                                isLoading={loading}
                                adElement={props.adElement}
                                withH1={
                                    props?.currentPath?.match(/bc-[0-9]+$/) !==
                                    null
                                }
                            />
                        ) : (
                            <VideoHubVideoSkin
                                {...(renderPlayerProps as RenderPlayerParams)}
                                hideSticky={props.hideSticky}
                                setHideSticky={props.setHideSticky}
                                publicUrl={props.publicUrl}
                                currentPath={props.currentPath}
                                onPlayNextVideo={props.onPlayNextVideo}
                                isLoading={loading}
                                withH1={
                                    props?.currentPath?.match(/bc-[0-9]+$/) !==
                                    null
                                }
                            />
                        )
                    ) : isNgnEnabled ? (
                        <PNVideoSkin
                            {...(renderPlayerProps as PNRenderPlayerParams)}
                            canAutoPlay={props.canAutoPlay}
                        />
                    ) : (
                        <VideoSkin
                            {...(renderPlayerProps as RenderPlayerParams)}
                            hideSticky={props.hideSticky}
                            setHideSticky={props.setHideSticky}
                        />
                    )
                }
                pathname={location.pathname}
                isVideoHubPlayListFixEnabled={isVideoHubPlayListFixEnabled}
            />
        </ErrorBoundary>
    )
})
Video.displayName = 'Video'
