import React from 'react'
import {
    PlayButton,
    PlayButtonProps,
} from '../../../../../buttons/PlayButton/PlayButton'
import { playOverlayClassName } from '../../../../../content/Video/components/PlayOverlay/PlayOverlay.common'
import { StyledPNPlayOverlay, StyledPNSpinner } from './PNPlayOverlay.styled'

export interface PNPlayOverlayProps extends PlayButtonProps {
    play: () => void
    autoPlay?: boolean
}

export const PNPlayOverlay: React.FC<PNPlayOverlayProps> = (props) => (
    <StyledPNPlayOverlay className={playOverlayClassName} onClick={props.play}>
        {props.autoPlay ? (
            <StyledPNSpinner />
        ) : (
            <PlayButton
                onClick={props.play}
                isCentered={props.isCentered}
                isInversed={props.isInversed}
                isSmall={props.isSmall}
            />
        )}
    </StyledPNPlayOverlay>
)
PNPlayOverlay.displayName = 'PNPlayOverlay'
