import styled from '@emotion/styled'
import { withClass } from '@news-mono/web-common'
import { breakpoint } from '../../../../__styling/style-functions/breakpoint'
import { colorsPN } from '@news-mono/design-tokens'

export const pnVideoClassname = 'pn-video-specificity'
interface StyledVideoProps {
    posterImage?: string
}

const { background } = colorsPN

export const StyledWrapper = styled('div')({
    position: 'relative',
    display: 'block',
})

export const StyledPNVideo = withClass(pnVideoClassname)(
    styled('div')<StyledVideoProps>(
        {
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            backgroundSize: 'cover',
            backgroundColor: background.strong,

            padding: '0 0 56.25%',
            minHeight: '80px',

            position: 'relative',
            overflow: 'hidden',
            [breakpoint('sm')]: {
                minHeight: '140px',
            },
            '.collection__card-media > &': {
                padding: 'auto',
                minHeight: 'unset',

                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                overflow: 'hidden',
            },
        },
        (props) => ({
            backgroundImage: props.posterImage && `url(${props.posterImage})`,
        }),
        {
            // the video className is added before the contextual selector to ensure specificity in ie10
            [`&.${pnVideoClassname} .vjs-big-play-button`]: {
                display: 'none',
            },

            [`&.${pnVideoClassname} .vjs-big-play-button .vjs-control-text`]: {
                position: 'relative',
                overflow: 'visible',
                clip: 'auto',
                margin: 0,
                padding: 0,
                border: 0,
                display: 'none',
            },
        },
    ),
)
StyledPNVideo.displayName = 'StyledPNVideo'
