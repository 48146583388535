import styled from '@emotion/styled'
import { zIndex } from '../../../../../__styling/settings/z-index'
import { breakpoint, calcRem } from '../../../../../__styling'
import { PNArticleCardTheme } from '../../../cards/PNCard.Theme'
import { colorsPN, metricsPN } from '@news-mono/design-tokens'
import { perthNowNGNTextMixin } from '../../../../../mixins'

export interface StyledPNTopBarProps {
    isVisible: boolean
    compactMode?: boolean
}

const { spacing } = metricsPN

export const StyledPNTopBar = styled('div')<StyledPNTopBarProps>(
    {
        position: 'absolute',
        zIndex: zIndex.common.videoTopBar,
        width: '100%',
        padding: calcRem(spacing.XS, spacing['S'], spacing['S']),
        transition: 'transform 100ms ease, opacity 100ms ease',
        pointerEvents: 'none',
        background: `linear-gradient(360deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 76%)`,
        color: colorsPN.text.inverse,

        ...perthNowNGNTextMixin.label.XS.medium,
        h3: {
            margin: spacing.none,
        },

        [breakpoint('sm')]: {
            padding: calcRem(spacing.XS, spacing['S']),
            ...perthNowNGNTextMixin.label.S.medium,
            h3: {
                paddingBottom: calcRem(spacing.XL),
            },
        },

        [breakpoint('lg')]: {
            padding: calcRem(spacing['S']),
            fontSize: calcRem(14),
            h3: {
                paddingBottom: calcRem(spacing.XS),
            },
        },
    },
    ({ isVisible }) =>
        isVisible === false && {
            transition: 'transform 1000ms ease',
            transform: 'translateY(-100%)',
        },
)

export const StyledPNInset = styled('div')({
    lineHeight: 1,
})

export const StyledPNHeading = styled('h3')({
    ...PNArticleCardTheme.S.kicker,

    overflow: 'hidden',
    margin: 0,
    marginBottom: calcRem(spacing['4XS']),
    width: '100%',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
})
