import { VideoDetails } from '@news-mono/web-common'
import React from 'react'
import { StyledPNHeading, StyledPNTopBar } from './PNVideoTopBar.styled'

export interface TopBarProps {
    visible: boolean
    videoDetails: VideoDetails
    compactMode?: boolean
    isAdPlaying?: boolean
}

export const PNVideoTopBar: React.FC<TopBarProps> = ({
    visible,
    videoDetails,
    isAdPlaying,
}) => {
    return (
        <StyledPNTopBar
            className="video-js__overlay video-js__overlay--pause"
            isVisible={visible}
        >
            <StyledPNHeading className="video-js__heading">
                {isAdPlaying ? 'Ad' : videoDetails.heading}
            </StyledPNHeading>
        </StyledPNTopBar>
    )
}

PNVideoTopBar.displayName = 'PNVideoTopBar'
