import { Product, scaleImage } from '@news-mono/web-common'
import React, { Fragment, useEffect, useState } from 'react'
import cx from 'classnames'
import {
    pnVideoClassname,
    StyledWrapper,
} from '../../../../content/Video/Video.styled'
import { getPosterImage } from '../../../../content/Video/utils/poster'
import { getImageWidth } from '../../../../content/Video/utils/product-info'
import { PNVideoTopBar } from '../components/PNVideoTopBar/PNVideoTopBar'
import { StyledPNVideo } from './PNVideo.styled'
import { PNPlayOverlay } from '../components/PNPlayOverlay/PNPlayOverlay'
import { PNVideoPlayButton } from '../../buttons/PNVideoPlayButton'
import { PNRenderPlayerParams } from './PNPlayerInterface'
import { isMobile } from '../../../../content/Video/utils/can-autoplay'

export const videoWrapperClassName = 'video-wrapper'
export const pnDismissControlClassName = 'pn-control-dismiss'
export const pnHidePlayButtonClassName = 'pn-play-hidden'

export const PNVideoSkin: React.FC<PNRenderPlayerParams> = ({
    wrapperElProps,
    videoElement,
    playerState,
    playerOptions,
    playerActions,
    canAutoPlay,
}) => {
    const {
        adStarted,
        adPlayed,
        showTopBar,
        videoStarted,
        videoPlaying,
        videoPaused,
    } = playerState
    const { playVideo, pauseVideo, handleUserInactive } = playerActions
    const [isInitialPlay, setIsInitialPlay] = useState(true)
    const [controlHidden, setControlHidden] = useState(true)

    const controlVisible = (adStarted || adPlayed) && showTopBar

    useEffect(() => {
        const duration = isInitialPlay ? 3000 : 1500
        controlVisible && setControlHidden(false)
        const timer = setTimeout(() => {
            setIsInitialPlay(false)
            setControlHidden(true)
            handleUserInactive()
        }, duration)

        return () => {
            clearTimeout(timer)
        }
    }, [controlVisible])

    const renderTopBar = (
        <PNVideoTopBar
            compactMode={playerOptions.compactMode}
            visible={!controlHidden}
            videoDetails={playerState.currentVideo}
            isAdPlaying={adStarted && !adPlayed}
        />
    )

    const renderPlayButton = (
        <PNVideoPlayButton
            overlayButtonScale="L"
            isCentred
            status={
                videoStarted && videoPlaying
                    ? 'playing'
                    : videoPaused
                    ? 'paused'
                    : 'hidden'
            }
            onPlayButtonClick={handlePlayButtonClick}
        />
    )

    const renderPlayOverlay =
        adStarted || videoStarted ? null : (
            <PNPlayOverlay
                play={playerActions.playVideo}
                isCentered={true}
                isSmall={playerOptions.smallPlayButton}
                autoPlay={canAutoPlay}
            />
        )

    const isTouchDevice = typeof navigator !== 'undefined' && isMobile.any()
    const { innerRef, ...StyledVideoWrapperProps } = wrapperElProps
    const posterImageCrop = getPosterImage(playerState.currentVideo)
    let posterImageScaled: string | undefined

    const extraClassNames = cx(
        controlHidden ? pnDismissControlClassName : '',
        videoStarted && !isTouchDevice ? pnHidePlayButtonClassName : '',
    )

    if (posterImageCrop) {
        posterImageScaled = scaleImage(
            posterImageCrop.reference,
            getImageWidth(Product.PerthNow),
            false,
        )
    }

    function handlePlayButtonClick() {
        if (!videoStarted) return
        if (videoPaused) {
            playVideo()
            return
        }
        if (videoPlaying) {
            pauseVideo()
            return
        }
    }

    return (
        <Fragment>
            {posterImageScaled && (
                <style
                    dangerouslySetInnerHTML={{
                        __html: `
                            @media print {
                                .${pnVideoClassname}::before {
                                    content: url("${posterImageScaled}");
                                    max-width: 600px;
                                }
                            }`,
                    }}
                />
            )}
            <StyledWrapper
                className={videoWrapperClassName}
                compactMode={playerOptions.compactMode}
                hide={playerOptions.compactMode}
            >
                {renderPlayOverlay}
                <StyledPNVideo
                    {...StyledVideoWrapperProps}
                    ref={innerRef}
                    posterImage={posterImageScaled}
                    className={extraClassNames}
                >
                    {renderTopBar}
                    {renderPlayButton}
                    {videoElement}
                </StyledPNVideo>
            </StyledWrapper>
        </Fragment>
    )
}
